/** 
 *   公共模块
 */
import { get, post } from '@/untils/js/axios.js'

// 上传图片
export const upload  = params => post('/pic/upload', params)

// 上传多图
export const uploads = params => post('/pic/uploads', params)

// 删除图片
export const delpic  = params => post(`/pic/delpic/${params}`)

// 上传多视频
export const videoUpload = params => post('/upload/video', params)



// 获取批次
export const getBatch  = params => get(`/goods/pre-sale-batch`, params)
