<template>

    <!-- 多选上传图片 -->

    <div class="m-img-uploads" >

      <!-- 上传图片显示 -->
      <div class="img-wrapper" v-for="(it,index) in images" :key="index" @click="handlePreview(index)">
          <img :src="it.url" alt="logo" >
          <div class="img-edit" >
              <img src="./../../assets/public/delete.png" @click.stop="handleRemove(it.pic_id, index)" alt="Delete">
          </div>
      </div>

        <!--  上传输入框 -->
      <label v-show="max == 0 || images.length < max"   class="input-label" for="upload">
        <van-loading v-if="load"  size="24" color="#1989fa" />
        <div v-else>
          <van-icon name="plus" :color="color" size="1.5em"  />
          <span>{{text}}</span>
        </div>
        <input @change="handleChange($event)" type="file" id="upload" accept="image/jpg,image/jpeg,image/png" multiple="multiple">
      </label>


    

      <!-- 大图预览 -->
      <van-image-preview v-model="show" :images="url" :startPosition="startPosition"></van-image-preview>

    </div>
      
    



</template>

<script>
import { uploads, delpic } from '@/api/public.js'

export default {
  name:"Uploads",
  props:{
    fileName:{
      type:String,
      default:'',
      required:true,
    },
    text: {
      type:String,
      default: '',
    },
    color: {
      type: String,
      default: '#1989fa'
    },
    imgs: {
      type: Array,
      default: () => [],
      required: true
    },
    max:{
      type:Number,
      default: 0,
    },
  },

  data() {
    return {
      images: this.imgs,
      show: false,
      url: [],
      startPosition: 0,
      load:false
    }
  },
  methods:{
    handleChange(e){
      let files = e.target.files;
      var image = new FormData()
      if(this.images.length + files.length > this.max && this.max != 0) {
        this.$notify({ type: 'warning', duration: 2000, message: `所传图片不能超过${this.max}张` })
        return false
      }

      image.append('filename', this.fileName)

      files.forEach( (e,index) => {
        image.append(`file[${index}]`, e)
      })
      this.load = true
      uploads(image).then(res => {
        if(res){
          this.images  = [...this.images, ...res.data]
          this.$emit('update:imgs', this.images)
        }
      }).finally(() => {
        this.load = false
      })

    },

    handleRemove(id, index) {
      if(id != "") {
        delpic(id).then( res => {
          if(res) {
            this.images.splice(index, 1)
            this.$emit('update:imgs', this.images)
          }
        })
      }
    },


    // 点击预览大图
    handlePreview(index) {
      this.startPosition = index
      this.url = []
      this.images.forEach(e => {
        this.url.push(e.url)
      })
      this.show = true
    }

  },
}
</script>

<style lang="less" scoped>
.m-img-uploads{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
    .img-wrapper {
      display: inline-block;
      position: relative;
      border-radius: 5px;
      margin-right: 20px;
      margin-bottom: 12px;
      width: 94px;
      height: 94px;
      img{ 
          object-fit: cover;
          border-radius: 4px;
          width: 100%;
          height: 100%;
      }
      .img-edit {
          position: absolute;
          top: -8px;
          right: -8px;
          >img {
            width: 14px;
            height: 14px;
          }
      }
    }
  .input-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #B5B5B5;
    border-radius: 5px;
    width: 94px;
    height: 94px;
    position: relative;
    >div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      >span {
        font-family: PingFangSC;
        font-size: 12px;
        line-height: 14px;
        color: #999999;
        margin-top: 8px;
      }
    }
    
    >input {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
}
  
</style>