<template>
  <section class="m-suggest-submit m-scroll">

    <!-- 头部导航 -->
    <m-header title="意见反馈">
      <span slot="right">提交</span>
    </m-header>

    <p class="type">
      <span>问题反馈类型：</span>
      <span>{{suggestion}}</span>
    </p>

    <div class="description">
      <textarea v-model="content" placeholder="请描述您遇到的问题…"></textarea>
      <div class="upload-wrap">
          <m-uploads :imgs.sync="images" file-name="suggestion" :max="3"></m-uploads>
      </div>
    </div>

    <div class="phone">
      <div class="label">
        <img src="@/assets/suggest/icon_dianhua@2x.png" alt="">
        <span>联系方式</span>
      </div>
      <input v-model="phone" class="right" type="text" placeholder="请输入您的联系方式，以便我们联系您">
    </div>

    <div class="mooby">
      <p>联系我们</p>
      <div class="wechat">
        <p>微信公众号</p>
        <p>MoobyYoho</p>
      </div>
      <div class="email">
        <p>官方微信</p>
        <p>customerservice@moobyyoho.com</p>
      </div>
    </div>


    <div class="footer">
      <van-button @click="handleSignOut" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">提 交</van-button>
    </div>





    

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import MUploads  from '@/components/zh/m-uploads.vue'
import { feedback } from '@/api/zh/mine.js'
export default {
  name:'Sugestion',
  components:{ MHeader, MUploads },
  data(){
    return {
      content:'',
      images:[],
      phone:'',
    }
  },

  computed: {
    suggestion() {
      if(this.$route.params.type == 1){
        return '售后、安装与发票问题'
      }
      else if(this.$route.params.type == 2){
        return '商品使用与质量'
      }
      else if(this.$route.params.type == 3){
        return '投诉与建议'
      }
      else if(this.$route.params.type == 4){
        return '购买活动与账号问题'
      }
      else if(this.$route.params.type == 5){
        return '其他'
      }
      else if(this.$route.params.type == 6){
        return 'APP使用体验问题与建议'
      }else{
        return '其他'
      }
    }
  },

  methods:{

    // 退出当前账号
    handleSignOut(){
      let pic = [], params = { title: this.suggestion }
      if(this.content == '') {
        this.$notify({ type: 'warning', duration: 2000, message: '请完善您的问题描述' })
        return false
      }else {
        params.describe = this.content
      }
      if(this.phone == '') {
        this.$notify({ type: 'warning', duration: 2000, message: '请完善您的连下方式' })
        return false
      }else {
        params.contact_us = this.phone
      }
      if(this.images.length != 0) {
        this.images.forEach(e => {
          pic.push(e.pic_id)
        })
        params.pic = pic.join(',')
      }

      feedback(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', duration: 2000, message: res.msg })
          this.$router.push('/zh/account')
        }
      })
      
    },
  
   
  },
}
</script>

<style lang="less" scoped>
.m-suggest-submit {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow: hidden;
  .type {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    font-size:12px;
    font-weight:400;
    color: #666;
    >span:nth-of-type(2) {
      font-size: 14px;
      color: #333;
    }
  }
  .description {
    width: 100%;
    min-height: 210px;
    background-color: #fff;
    padding: 12px 16px;
    position: relative;
    >textarea {
      border: none;
      outline: none;
      width: 100%;
      height: 125px;
      padding: 8px 5px;
      resize: none;
      line-height: 20px;
      background: #F4F9FF;
      border-radius: 5px;
      margin-bottom: 10px;
    }
    >textarea::placeholder {
      font-size:12px;
      
      font-weight:400;
      color:rgba(153,153,153,1);
      line-height:22px;
    }
    .upload-wrap {
      display: flex;
      position: relative;
      .upload-video {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
      }
    }
  }
  .phone {
    margin-top: 10px;
    height: 50px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    background-color: #fff;
    .label {
      display: flex;
      align-items: center;
      margin-right: 16px;
      >img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
      >span {
        font-weight:400;
        color:000;
        line-height:16px;
        font-size: 14px;
      }
    }
    .right {
      flex: 1;
      height: 50px;
      border: none;
      outline: none;
      appearance: none;
    }
    .right::placeholder {
      font-size:12px;
      
      font-weight:400;
      color: #666;
      line-height:17px;
    }
  }
  .mooby {
    margin-top: 10px;
    background-color: #fff;
    padding: 0 16px;
    >p {
      height: 37px;
      line-height: 37px;
      font-size:14px;
      
      font-weight:400;
      color: #000;
    }
    .wechat, .email {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      >p:nth-of-type(1) {
        font-size:12px;
        font-weight:400;
        color: #666;
      }
      >p:nth-of-type(2) {
        font-size:12px;
        font-weight:400;
        color: #000;
      }
    }
  }
  .footer {
    margin-top: 40px;
    padding: 0 46px;
  }
}
</style>